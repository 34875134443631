<template>
  <div class="errPage">
    <div class="errPage-container">
      <img src="/assets/404.png" width="400" :alt="$t(`errorPage.404.title`)" />
      <h1 class="oops">Oops!</h1>
      <h2 class="error-title">{{ $t(`errorPage.404.title`) }}</h2>
      <h5 class="error-description">{{ $t(`errorPage.404.description`) }}</h5>
      <el-button @click="$router.push({ path: '/' })" icon="arrow-left" class="back-btn" aria-label="Torna indietro">{{ $t(`errorPage.back`) }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page404'
};
</script>

<style lang="less" scoped>
  @import url(./errorPages.less);
</style>
